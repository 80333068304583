import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";

const templatePage = () => {
    return (
      <Layout>
         <Seo
        title="Template"
        description="Customize your plate design with these templates."/>
        <div className="flex flex-col lg:flex-row justify-center items-center lg:h-screen">
          <h1 className="lg:w-1/2 text-right text-6xl font-semibold mx-5 pt-20">Templates for<br/> custom quicksticks</h1>
          <div className="lg:w-1/2 pt-10 lg:pt-0">
            <p className="xl:w-3/4 text-lg mx-5">
            Feel free to change the templates to your liking.<br/>

We've included two designs, but you are free to use
any text, font or icon in any way you want. <br/>

If you don't want fields like the title or director/dop engraved, just delete them.<br/>

Keep in mind that we cannot produce color images.<br/>
All engraved areas will be filled with black ink.<br/>

If you incorporate logos or similar, make sure these are embedded as vector files, as we cannot ensure correct reproduction of bitmap images (.jpg, .bmp etc.). If you need to though, please use .png images. Make sure they are greyscale, as we aren't able to produce color images.<br/>

Feel free to use the same fonts we use, they are 
Space Grotesk and Space Mono.<br/>
You can find them on fonts.google.com
<br/><br/>


When you are done, please convert all text areas to curves, as we cannot ensure correct reproduction of fonts.<br/>

Please send your finished design as .pdf to include all embedded elements. <br /><br />

Click on one of the designs to download it.
</p>
<div className="flex flex-row justify-center items-center m-5 xl:w-3/4 space-x-5">
<a href="/download/slate-template-d1.svg" download>
    <StaticImage src="../images/quicksticks/d1.png" className="w-full" alt="slate template 1"/>
</a>
<a href="/download/slate-template-d2.svg" download>
    <StaticImage src="../images/quicksticks/d2.png" className="w-full" alt="slate template 2"/>
</a>
</div>
          </div>
        </div>
      </Layout>
  )
}

export default templatePage